import React, { useEffect } from "react";
import Layout from "../../layouts/zh";
import Reward from "../../services/RewardsDetail";
import langData from "../../data/static-pages/pages/rewards/zh";
import { navigate } from "gatsby";
import { useAuth0 } from "@auth0/auth0-react";
import { saveState } from "../../state/utils/localStorage";
import { Spin } from "antd";
import { getGuestCompleteInfo } from "../../apis/guest";

const RedemptionPage = ({ ...props }) => {
  const {
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
  } = useAuth0();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      const href = `${window.location.pathname}${window.location.search}`;
      saveState("redirectUrl", href);
      loginWithRedirect({
        authorizationParams: {
          ui_locales: "zh-CN",
          redirect_uri: `${window.location.origin}/zh/callback`,
        },
        appState: {
          target: "redirectUrl",
        },
      });
    } else {
      const handleCallback = async () => {
        const token = await getAccessTokenSilently();
        const guestInfo = await getGuestCompleteInfo(token);
        if (Object.keys(guestInfo).length > 0) {
          if (!guestInfo?.data?.data?.guest?.isEmailVerify) {
            return navigate(
              `/zh/verify-account?email=${guestInfo?.data?.data?.guest?.email}`,
              {
                replace: true,
              }
            );
          }
          if (
            !guestInfo?.data?.data?.guest?.firstName ||
            !guestInfo?.data?.data?.guest?.lastName
          ) {
            return navigate(`/zh/complete-profile`, {
              replace: true,
            });
          }
        }
      };
      handleCallback();
    }
  }, [isAuthenticated, isLoading]);

  return (
    <Layout location={props.location}>
      {!isAuthenticated ? (
        <div
          style={{
            display: "flex",
            marginTop: "64px",
            justifyContent: "center",
          }}>
          <Spin />
        </div>
      ) : (
        <Reward
          baseUrl={props.location.origin}
          params={props.location.search}
          langKey={props.pageContext.langKey}
          pathname={props.location.pathname}
          slug={props["*"]}
          landingUrl={"/zh/reward"}
          loginUrl={"/login"}
          langData={langData}
        />
      )}
    </Layout>
  );
};

export default RedemptionPage;
